import { Platform } from '@ionic/angular';
import { AuthService } from './../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationsService } from '../notifications/notifications.service';

export interface AnalyticsData {
  app_name?: string;
  app_version?: string;
  app_environment?: string;
  userid?: string;
  event_type?: string;
  event_name?: string;
  event_detail?: string;
  device_platform?: string;
  device_id?: string;
  device_os?: string;
  device_os_version?: string;
  device_orientation?: string;
  session_token?: string;
  data?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BhAnalyticsService {
  public analyticsData: AnalyticsData = {};
  public browser: any;
  public browserVersion: any;

  constructor(
    public http: HttpClient,
    public platform: Platform,
    // public device: Device,
    public auth: AuthService,
    public notifications: NotificationsService
  ) {
    const ms = Date.parse(new Date().toISOString());
    this.analyticsData.session_token = ms.toString();
    this.getbrowserinfo();
  }
  // ******ANALYTICS Calls******* */
  public screenView(screen: any) {
    this.saveAnalytics('page-view', screen, '').subscribe(data => {});
  }
  public searchEvent(searchStr: any) {
    this.saveAnalytics('click', 'search', searchStr).subscribe(data => {});
  }
  public clickEvent(eventname: any, eventDetail: any) {
    this.saveAnalytics('click', eventname, eventDetail).subscribe(data => {});
  }
  public loginEvent(userid: any) {
    this.saveAnalytics('click', 'login', userid).subscribe(data => {});
  }
  public customEvent(eventType: any, eventname: any, eventDetail: any) {
    this.saveAnalytics(eventType, eventname, eventDetail).subscribe(data => {});
  }

  // ******ANALYTICS******* */
  public initAnalytics() {
    this.analyticsData.app_name = environment.APPNAME;
    this.analyticsData.app_version = environment.APPVERSION;
    this.analyticsData.app_environment = environment.ENV;
    //
    // SET THE USER ID FROM AUTH - For worktips done in home-ts
    //
    let platform_info = this.platform.platforms();
    let fullplatform = '';
    platform_info.forEach(function(value) {
      console.log(value);
      fullplatform += value + ' ';
    });
    if (fullplatform.includes('cordova')) {
      // this.analyticsData.device_platform =
      //   this.device.manufacturer + ' ' + this.device.model;
      // this.analyticsData.device_id = this.device.uuid;
      // this.analyticsData.device_os = this.device.platform;
      // this.analyticsData.device_os_version = this.device.version;
    } else {
      // Get browser info
      this.analyticsData.device_platform = fullplatform;
      this.analyticsData.device_os = this.browser;
      this.analyticsData.device_os_version = this.browserVersion;
    }
  }
  public saveAnalytics(
    eventtype: any,
    eventName: any,
    eventdetail: any
  ): Observable<any> {
    if (!this.analyticsData.app_name) {
      this.initAnalytics();
    }
    if (this.platform.isLandscape()) {
      this.analyticsData.device_orientation = 'LANDSCAPE';
    } else if (this.platform.isPortrait()) {
      this.analyticsData.device_orientation = 'PORTRAIT';
    }
    //
    this.analyticsData.event_type = eventtype;
    this.analyticsData.event_detail = eventdetail;
    this.analyticsData.event_name = eventName;
    const body = this.analyticsData;
    const url = `${environment.VERLOCKER_URL}/analytics`;
    const httpOptions = this.auth.getHttpHeaders();

    // console.log("analytics body", body);

    // return this.http.post(url, body, options)
    //   .map((res) => this.extractanalytics(res))
    //   .catch((err) => this.handleError(<any>err));

    // return this.http
    //   .post(url, "", httpOptions)
    //   .pipe(map(data => {}))
    //   .subscribe((data: any) => {
    //     //Check for error messages
    //     if (data.x_status == "E") {
    //       this.notifications.showError(
    //         data,
    //         "An unexpected error has occurred."
    //       );
    //     } else {
    //       this.extractanalytics(data);
    //     }

    //     return data.result;
    //   })
    //   .catchError(err => {
    //     //user notifications
    //     return of(err);
    //   });

    return this.http.post(url, body, httpOptions).pipe(
      map((data: any) => {
        if (data.x_status === 'E') {
          this.notifications.handleError(
            data,
            'An unexpected error has occurred.'
          );
        } else {
          this.extractanalytics(data);
        }

        return data.result;
      }),
      catchError(err => {
        // user notifications
        return of(err);
      })
    );
  }

  public extractanalytics(res: Response) {
    const body = res.json();
    // console.log("analytics return body", body);
    return body || {};
  }

  // ******Error Handler******* */
  handleError(error: any): Observable<any> {
    console.log(error);
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg);
    return Observable.throw(errMsg);
  }
  // ******Browser Info******* */
  public getbrowserinfo() {
    let unknown = '-';
    // screen
    let screenSize = '';
    let width;
    let height;
    if (screen.width) {
      width = screen.width ? screen.width : '';
      height = screen.height ? screen.height : '';
      screenSize += '' + width + ' x ' + height;
    }
    // browser
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;
    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    } else if (nAgt.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if (
      (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))
    ) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) { version = version.substring(0, ix); }
    if ((ix = version.indexOf(' ')) != -1) { version = version.substring(0, ix); }
    if ((ix = version.indexOf(')')) != -1) { version = version.substring(0, ix); }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    // mobile version
    let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
    // cookie
    let cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled =
        document.cookie.indexOf('testcookie') != -1 ? true : false;
    }
    // system
    let os = unknown;
    let clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
      }
    ];
    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion = '';

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        const regExResult = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = regExResult[1] + '.' + regExResult[2];
        break;
    }
    const browserInfo = {
      screen: screenSize,
      browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile,
      os,
      osVersion
    };
    console.log(browserInfo);
    this.browser = browser;
    this.browserVersion = version;
  }

  // getAll(activeOnly: boolean): Observable<UserProfile[]> {
  //   if (this.activeCurrentUser) {
  //     let url = `${ENV.HOST}/getUsersAll`;
  //     let httpOptions = this.getHttpHeaders();

  //     return this.http
  //       .post(url, "", httpOptions)
  //       .map((res: UserCollection) => {
  //         //Check for error messages
  //         if (res.status == "E") {
  //           this.userNotifications.showError(
  //             res,
  //             "An unexpected error has occurred."
  //           );
  //         }

  //         return res.result;
  //       })
  //       .catch(err => {
  //         //user notifications
  //         return Observable.of(err);
  //       });
  //   }
  // }
}
