import { BhAppVersionComponent } from './../../components/bh-app-version/bh-app-version.component';
import { BhLogoComponent } from './../../components/bh-logo/bh-logo.component';
import { BhHeaderComponent } from './../../components/bh-header/bh-header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
  MatToolbarModule,
  MatButtonModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatDividerModule,
  MatGridListModule,
  MatSelectModule,
  MatDatepickerModule,

  MatNativeDateModule,
  MatTabsModule,
  MatAutocompleteModule
} from '@angular/material';
import { BhValidationErrorComponentModule } from 'src/app/components/bh-validation-error/bh-validation-error.component.module';



@NgModule({
  declarations: [
    BhAppVersionComponent,
    BhHeaderComponent,
    BhLogoComponent,
    // BhValidationErrorComponent,
  ],
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [
    BhAppVersionComponent,
    BhHeaderComponent,
    BhLogoComponent,
    // BhValidationErrorComponent,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDividerModule,
    MatGridListModule,
    MatSelectModule,
    MatNativeDateModule,
    MatTabsModule,
    MatAutocompleteModule,
    BhValidationErrorComponentModule
  ]
})
export class BhComponentsModule { }
