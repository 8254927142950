import { CustomError } from './../../models/custom-error';
import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { OverlayBaseController } from '@ionic/angular/dist/util/overlay';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  loadingNow = false;
  loading: any;
  errorList: CustomError[] = [];

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private router: Router
  ) {
  }

  /***
   * Display loading spinner
   */
  async startLoading() {
    this.loading = await this.loadingController.create({
      spinner: 'crescent',
      translucent: true,
    });
    this.loadingNow = true;
    return await this.loading.present();
}

  /***
   * Hide loading spinner
   */
  async stopLoading() {
    if (this.loading) {
      this.loadingNow = false;
      return await this.loading.dismiss();
    }
  }

  /**
   * Displays alert message
   * @param header Header text of the alert message
   * @param message subHeader or body text of the alert message
   * @param dismissAfter Milliseconds to dismiss this alert after
   */
  async showAlert(header?: string, message?: string, dismissAfter?: number) {
    const options: AlertOptions = {
      header,
      message,
      buttons: ['Dismiss']
    };

    const alert = await this.alertController.create(options);

    await alert.present();
    if (dismissAfter) {
      setTimeout(() => {
        alert.dismiss();
      }, dismissAfter);
    }
  }

  /**
   * Displays toast message at the top of screen
   * @param msg Message to display in toast
   * @param dismissAfter Milliseconds to dismiss the toast
   */
  async showToast(msg: string, dismissAfter: number = 5000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dismissAfter,
      position: 'top',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            console.log('Dismiss clicked');
          }
        }
      ]
    });

    return await toast.present();

  }

  /**
   * Handles errors and user notifications.  Sends error contents to console, parses error for toast, displays error toast.
   * @param errorData  Send error of any format for parsing (httpErrorResponse, string, etc.)
   * @param userFriendlyText (optional) Define user-friendly error text.
   * Leave out or empty to display error object's message; Send 'skip_toast' to suppress error toast.
   */
  async handleError(errorData: any, action: string, parameters: any = null, userFriendlyText: string = null) {
    let msg = '';
    let showToast = true;

    // Console log error/response obj and remove outstanding loading modal
    console.log(errorData);
    this.stopLoading();

    // Parse error message
    if (typeof errorData === 'string') {
      msg = errorData;
    } else if (errorData.errors) {
      msg = `Status: ${errorData.statusText} - ${errorData.errors[0].messages[0]}`;
    } else if (errorData.error && errorData.error.errors) {
      msg = `Status: ${errorData.statusText} - ${errorData.error.errors[0].messages[0]}`;
    } else if (errorData.error && !errorData.error.errors) {
      msg = errorData.error.message;
    } else {
      msg = errorData.message;
    }

    // Add error to error list
    const err: CustomError = {
      message: msg,
      page: this.router.url,
      timestamp: new Date().toISOString(),
      errorData
    };
    this.errorList.push(err);

    // Swap out error user-friendly text
    if (userFriendlyText && userFriendlyText.trim().length > 0) {
      msg = userFriendlyText.trim();
    }

    // Check for error exceptions
    switch (msg.trim().toLowerCase()) {
      case 'skip_toast':
      case 'cordova_not_available':
      case 'plugin_not_installed':
      case 'key [_ss_un] not found.':
        showToast = false;

    }

    // Show error toast
    if (showToast && userFriendlyText) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 5000,
        position: 'top',
        cssClass: 'warning',
        buttons: [
          {
            text: 'Dismiss',
            role: 'cancel',
            handler: () => {
              // console.log('Dismiss clicked');
            }
          }
        ]
      });

      toast.present();

    }
  }
}
