import { VerlockerService } from './../verlocker/verlocker.service';
import { NavController } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { BhAnalyticsService } from '../bhanalytics/bhanalytics.service';

@Injectable({
  providedIn: 'root'
})
export class LoginRouteGuardService implements CanActivate {
  env = environment;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    // authService.isLoggedIn().subscribe(val => this.isLoggedIn = val);
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const allowNavigate = (this.authService.authUser && this.authService.authUser.userId !== null);
    if (!allowNavigate) {
      return this.router.parseUrl('/login');
    }
    return allowNavigate;
  }
}
