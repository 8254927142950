import { NotificationsService } from './../../services/notifications/notifications.service';
import { HelpForgotPwdPage } from './../help-forgot-pwd/help-forgot-pwd.page';
import { AuthService } from './../../services/auth/auth.service';
import { BhAnalyticsService } from './../../services/bhanalytics/bhanalytics.service';
import { Component, OnInit } from '@angular/core';
import { Events, ToastController, NavController } from '@ionic/angular';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  environment = environment;
  loginCredentials: { userName?: string; password?: string } = {};
  submitted = false;
  properties = {};
  isToastShown = false;
  showBioButton = false;
  bioId = true;
  version?= '';
  form1: FormGroup = this.formBuilder.group({
    userName: ['', Validators.required],
    password: ['', Validators.required]
  });
  errorMessage?: any;
  isAuthenticating = false;


  public validationMessages = {
    userName: [{ type: 'required', message: 'Username is required.' }],
    password: [{ type: 'required', message: 'Password is required.' }]
  };

  constructor(
    public auth: AuthService,
    public analytics: BhAnalyticsService,
    public events: Events,
    public toastController: ToastController,
    public navController: NavController,
    public notifications: NotificationsService,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit() { }

  ionViewDidEnter() {
    this.analytics.screenView('Login Page');
    // Verlocker Test
    this.events.publish('CHECK_VERSION');
  }

  async login(form) {
    this.submitted = true; // need this for form validation errors
    const userName = this.form1.controls.userName.value;
    const password = this.form1.controls.password.value;
    // console.log('attempting login...');
    if (form.valid) {
      // **** Uncomment login block below when working on the login procedure
      // ****************************************************************************
      //
      if (this.form1.valid) {
        this.analytics.loginEvent(userName);
        this.errorMessage = '';
        try {
          this.isAuthenticating = true;
          const res = await this.auth.login(userName, password).toPromise();
          console.log('Login', res);
          if (res.userId) {
            this.navController.navigateRoot('/home');
            this.isAuthenticating = false;
          } else {
            console.log(res.x_msg);
            this.errorMessage = 'Sorry, that username or password isn\'t right. Please try again.';
            this.isAuthenticating = false;
          }
        } catch (err) {
            console.log('Login Error', err);
            this.errorMessage = 'Sorry, that username or password isn\'t right. Please try again.';
            this.notifications.handleError(err, 'login.login()');
            this.isAuthenticating = false;
        }
      }

    }
  }

  forgotPassword() {
    this.navController.navigateForward('/help-forgot-pwd');
  }

  returnToPortal() {
    location.href = 'https://mybaystate.org';
  }
}
