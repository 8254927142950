import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { NavController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { VerlockerData } from 'src/app/services/verlocker/verlocker.service';

@Component({
  selector: 'app-verlocker',
  templateUrl: './verlocker.page.html',
  styleUrls: ['./verlocker.page.scss'],
})
export class VerlockerPage implements OnInit {
  verData: VerlockerData = {
    // app: 'TEST',
    // android_install_link: 'https://baystatehealth.org',
    // ios_install_link: 'https://baystatehealth.org',
    // latest_version: '1.0.0',
    // status: 'S',
    // update_description: 'This is a test update description.',
    // verstatus: 'REQUESTED'
  };
  platformConstants = {
    ANDROID: 'android',
    IOS: 'ios',
    CORDOVA: 'cordova',
    CORE: 'core',
    MOBILEWEB: 'mobileweb'
  };

  constructor(
    public navController: NavController,
    public navParams: NavParams,
    public platform: Platform,
    // public inAppBrowser: InAppBrowser,
    public modalController: ModalController

  ) { 
    this.verData = this.navParams.data;
  }

  ngOnInit() {
  }

  update() {
    if (this.platform.is('android')) {
      window.open(this.verData.android_install_link, '_system');
    } else if (this.platform.is('ios')) {
      let url = decodeURIComponent(this.verData.ios_install_link+'');
      if(this.platform.is('cordova')){
        // this.inAppBrowser.create(url, '_system');
      } else {
        // running on iOS Safari browser
        window.open(url, '_self');
      }
    } else {
      console.log('Upgrade App-Not a valid platform');
      alert('This device cannot receive the newest update.');
    }
  }
  cancel() {
    this.modalController.dismiss();
  }


}
