import { environment } from 'src/environments/environment';
import { User } from './../../models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { NotificationsService } from '../notifications/notifications.service';

export enum AuthState {
  LOGGED_IN,
  LOGGED_OUT,
  EXPIRED
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authUser: User = {};
  public authState: AuthState;
  public API_URL: any;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storage: Storage,
    private router: Router
  ) { }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  /***
   * Gets global headers for Http requests
   */
  getHttpHeaders() {
    if (this.authUser && this.authUser.token) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.authUser.token
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
    }
  }

  /***
   * Sets the authenticated user's current state
   */
  setAuthState(newAuthState: AuthState) {
    if (newAuthState !== this.authState) {
      this.authState = newAuthState;
    }
  }

  /***
   * Log on service
   */
  login(userName: string, password: string): Observable<any> {
    const payload: any = Object.assign({}, this.authUser);
    payload.userId = userName;
    payload.password = password;

    const body = JSON.stringify(payload);
    const httpOptions = this.getHttpHeaders();
    const url = environment.API_URL + '/admin/login';
    // console.log("login",url,body);

    return this.http.post(url, body, httpOptions).pipe(
      map((data: any) => {
        if (data.userId) {
          this.authUser.userId = userName;
          this.authUser.firstName = data.usr_first_name;
          this.authUser.lastName = data.usr_last_name;
          this.authUser.role = data.usr_role;
          this.authUser.roleName = this.getRoleName(this.authUser.role);
          this.authUser.token = data.token;
          this.storage.set('user', this.authUser);
        } else {
          this.notifications.handleError(
            data,
            'An unexpected error has occurred.'
          );
        }

        return data;
      }),
      catchError(err => {
        // user notifications
        return of(err);
      })
    );
  }

  async getAuthToken() {
    const user: User = await this.storage.get('user') as User;
    return user ? user.token : undefined;
  }

  getRoleName(role) {
    switch (role) {
      case 'USER':
        return 'Content Manager';
      case 'ADMIN':
        return 'System Admin';
      default:
        return 'Unknown Role';
    }

  }


}
