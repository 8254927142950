export const environment = {
    PRODUCTION: false,
    API_URL: 'https://mobiledev.api.baystatehealth.org/mybaystate',
    VERLOCKER_URL: 'https://mobiledev.api.baystatehealth.org/verlocker',
    APPNAME: 'PATPORTADMIN',
    APPVERSION: '1.4.4',
    APPDESCRIPTION: 'MyBaystate Console',
    APPTAGLINE: '',
    APPSTOREURL: 'https://mobile.baystatehealth.org/appstore/patientgateway',
    ENV: 'DEV',
  };
  