import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError, from } from 'rxjs';
import { catchError, mergeMap, map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { NotificationsService } from './notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private notificationService: NotificationsService) {}

  // Intercepts all HTTP requests!
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.getAuthToken()).pipe(
      switchMap(token => {
        const clonedReq = this.addToken(request, token);
        return next.handle(clonedReq).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              console.log('event--->>>', event);
            }
            return event;
          }),
          catchError(error => {
            // Perhaps display an error for specific status codes here already?
            console.log(error);
            if (error instanceof HttpErrorResponse) {
              const msg = error.message;
              if (error.status === 401) {
                // this.notificationService.showAlert('Invalid credentials or session expired');
                this.authService.redirectToLogin();
              } else {
                // this.notificationService.showAlert(msg);
              }
            }
            // Pass the error to the caller of the function
            return throwError(error);
          })
        );
      })
    );
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          // Disabled these headers because they were overriding upload required headers
          // Accept: `application/json`,
          // 'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`
        }
      });
      return clone;
    }

    return request;
  }
}
