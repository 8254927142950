import { AdminRouteGuardService } from './services/admin-route-guard/admin-route-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginRouteGuardService } from './services/login-route-guard/login-route-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [LoginRouteGuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'verlocker',
    loadChildren: () => import('./pages/verlocker/verlocker.module').then(m => m.VerlockerPageModule)
  },
  {
    path: 'help-forgot-pwd',
    loadChildren: () => import('./pages/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'content/:itemType',
    loadChildren: './pages/news/news.module#NewsPageModule',
    canActivate: [LoginRouteGuardService],
  },
  {
    path: 'content/:itemType/:slideId',
    loadChildren: './pages/news-detail/news-detail.module#NewsDetailPageModule',
    canActivate: [LoginRouteGuardService],
  },
  {
    path: 'distribution',
    loadChildren: './pages/distribution/distribution.module#DistributionPageModule',
    canActivate: [LoginRouteGuardService, AdminRouteGuardService],

  },
  {
    path: 'distributions/:distributionId',
    loadChildren: () => import('./pages/distribution-details/distribution-details.module').then(m => m.DistributionDetailsPageModule),
    canActivate: [LoginRouteGuardService, AdminRouteGuardService],
  },
  {
    path: 'users',
    loadChildren: './pages/users/users.module#UsersPageModule',
    canActivate: [LoginRouteGuardService, AdminRouteGuardService],
  },
  {
    path: 'users/:userId',
    loadChildren: './pages/users-edit/users-edit.module#UsersEditPageModule',
    canActivate: [LoginRouteGuardService, AdminRouteGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
