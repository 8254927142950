import { AuthService } from './../../services/auth/auth.service';
import { environment } from './../../../environments/environment.dev';
import { Component, OnInit } from '@angular/core';
import { Events, MenuController, NavController } from '@ionic/angular';

@Component({
  selector: 'bh-header',
  templateUrl: './bh-header.component.html',
  styleUrls: ['./bh-header.component.scss'],
})
export class BhHeaderComponent implements OnInit {
  public env = environment;
  tilt = true;
  color = true;

  constructor(
    public events: Events,
    public menu: MenuController,
    public auth: AuthService,
    private navController: NavController,
  ) { }

  ngOnInit() { }

  openMenu() {
    this.menu.open('mainMenu');
  }


  // openMenu() {
  //   this.auth.authUser = {};
  //   this.menu.close();
  //   this.navController.navigateRoot('login');
  // }

}
