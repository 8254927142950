import { AuthService } from './../auth/auth.service';
import { AlertController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { VerlockerPage } from 'src/app/pages/verlocker/verlocker.page';
import { NotificationsService } from '../notifications/notifications.service';

export interface VerlockerData {
  app?: string;
  android_install_link?: string;
  ios_install_link?: string;
  latest_version?: string;
  status?: string;
  update_description?: string;
  verstatus?: string;
}

@Injectable({
  providedIn: 'root'
})
export class VerlockerService {
  public verlockerData: VerlockerData = {};
  constructor(
    public http: HttpClient,
    private alertController: AlertController,
    public modalController: ModalController,
    public auth: AuthService,
    public notifications: NotificationsService
  ) {
    // console.log('Hello VerlockerProvider Provider');
  }
  checkVersion(): Observable<any> {
    const body = {
      app: environment.APPNAME,
      version: environment.APPVERSION
    };
    const httpOptions = this.auth.getHttpHeaders();
    const url = environment.VERLOCKER_URL + '/versionCheck';
    // console.log(url);

    return this.http.post(url, body, httpOptions).pipe(
      map((data: any) => {
        if (data.x_status === 'E') {
          this.notifications.handleError(
            data,
            'An unexpected error has occurred.'
          );
        } else {
          this.processData(data);
        }

        return data.result;
      }),
      catchError(err => {
        // user notifications
        return of(err);
      })
    );
  }

  async processData(res: Response) {
    const body: any = res;
    // console.log(JSON.stringify(res));
    console.log(body);
    // body.verstatus == "EXPIRED" App / Version not in database. Allow entry
    if (body.verstatus == 'REQUIRED' || body.verstatus === 'REQUESTED') {
      const modal =  await this.modalController.create({
        component: VerlockerPage,
        componentProps: body,
        backdropDismiss: false
      });
      return await modal.present();
    } else {
      console.log('Is current: ', body.verstatus);
    }
    // console.log("wait to return");
    return body || {};
  }

  async testModal() {
    const verData: VerlockerData = {
      app: 'TEST',
      android_install_link: 'https://baystatehealth.org',
      ios_install_link: 'https://baystatehealth.org',
      latest_version: '1.0.0',
      status: 'S',
      update_description: 'This is a test update description.',
      verstatus: 'REQUESTED'
    };

    const modal =  await this.modalController.create({
      component: VerlockerPage,
      componentProps: verData,
      backdropDismiss: false
    });
    return await modal.present();

  }

  handleError(error: any): Observable<any> {
    console.log(error);
    const errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.log(errMsg);
    return Observable.throw(errMsg);
  }

  async presentRequested() {
    console.log('verlocker: presentRequested called');
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const alert = await this.alertController.create({
      header: 'Update available',
      message: 'Update this app to get access to the newest features, fixes, and best experience! ',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            console.log('Cancel clicked');
            resolveFunction(false);
          }
        },
        {
          text: 'Update',
          handler: () => {
            console.log('Update clicked');
            resolveFunction(true);
          }
        }
      ]
    });
    await alert.present();
    return promise;
    // console.log('#####  verlocker > presentRequested: alert.present() called #####');
  }
}
