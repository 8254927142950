import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, HostBinding  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'bh-validation-error',
  templateUrl: './bh-validation-error.component.html',
  styleUrls: ['./bh-validation-error.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BhValidationErrorComponent),
      multi: true
    }
  ],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ 'max-height': 0, opacity: 0 }),
        animate('500ms', style({ 'max-height': '200px', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ 'max-height': '200px', opacity: 1 }),
        animate('500ms', style({ 'max-height': 0, opacity: 0 }))
      ])
    ])
  ]

})
export class BhValidationErrorComponent implements ControlValueAccessor {
  @Input() validationMessages: any;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() submitAttempted: boolean;
  @Input() padding: string;

  constructor() { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {}

  registerOnChange(fn) {
    // this.onChange = fn;
  }

  writeValue(value) {
    // if (value) {
      // this.value = value;
    // }
  }

  registerOnTouched(fn) {
    // this.onTouched = fn;
  }

}
