import { environment } from './../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bh-app-version',
  templateUrl: './bh-app-version.component.html',
  styleUrls: ['./bh-app-version.component.scss'],
})
export class BhAppVersionComponent implements OnInit {
  @Input() color : string = '#000';
  public ENV = environment;

  constructor() { }

  ngOnInit() {}

}
