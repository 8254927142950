import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bh-logo',
  templateUrl: './bh-logo.component.html',
  styleUrls: ['./bh-logo.component.scss'],
})
export class BhLogoComponent implements OnInit {
  @Input() height = '32px';
  @Input() align = 'left';
  @Input() logoType = 'simple';
  logoImage = '/assets/bh-logos/bh-ac-stacked-wh.png';
  logoImageCss = 'url(\'' + this.logoImage + '\')';

  constructor() { }

  ngOnInit() {
    this.logoImage = (this.logoType === 'simple') ? '/assets/bh-logos/bh-wh.png' : '/assets/bh-logos/bh-ac-stacked-wh.png';
    this.logoImageCss = 'url(\'' + this.logoImage + '\')';
  }

}
