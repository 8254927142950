import { PipesModule } from './pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { LoginPageModule } from './pages/login/login.module';
import { BhComponentsModule } from './modules/bh-components/bh-components.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { Device } from '@ionic-native/device/ngx';
import { VerlockerPageModule } from './pages/verlocker/verlocker.module';
import { InterceptorService } from './services/interceptor.service';
import { IonicStorageModule } from '@ionic/storage';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BhComponentsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LoginPageModule,
    VerlockerPageModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    // Device,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
