import { UserRolePipe } from './pipes/user-role/user-role.pipe';
import { PipesModule } from './pipes/pipes.module';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationsService } from './services/notifications/notifications.service';
import { VerlockerService } from './services/verlocker/verlocker.service';
import { AuthService } from './services/auth/auth.service';
import { Component } from '@angular/core';

import { Platform, MenuController, Events, NavController, ModalController } from '@ionic/angular';
import { map, catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userRole: string;

  constructor(
    private platform: Platform,
    private menu: MenuController,
    private events: Events,
    public auth: AuthService,
    private navController: NavController,
    private verlocker: VerlockerService,
    private notifications: NotificationsService,
    private modalController: ModalController,
    private router: Router,
  ) {
    this.initializeApp();

    router.events.subscribe((val) => {
      console.log(val instanceof NavigationEnd);
      if (val instanceof NavigationEnd) {
        this.checkForUser();
      }
    });

    // Listen for open login
    this.events.subscribe('CHECK_VERSION', () => {
      // Test verlocker modal
      // this.verlocker.testModal();

      // Check version with Verlocker
      this.checkVersion();
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }

  open(page) {
    this.menu.close();
    this.navController.navigateForward(page);
  }

  logOff() {
    this.auth.authUser = {};
    this.menu.close();
    this.navController.navigateRoot('login');
  }

  checkVersion() {
    // Version Check
    this.verlocker.checkVersion().subscribe(
      data => {
        console.log('****checkVersion****', data);
      },
      err => {
        console.log('****ERROR IN checkVersion****');
      }
    );
  }

  checkForUser() {
    console.log('Route', this.router.url, this.auth.authUser);
    if (!this.auth.authUser.userId && this.router.url !== '/login' && this.router.url !== '/help-forgot-pwd') {
      this.navController.navigateRoot('/login');
    }
  }
}
