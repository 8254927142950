import { VerlockerService } from './../verlocker/verlocker.service';
import { NavController } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { BhAnalyticsService } from '../bhanalytics/bhanalytics.service';

@Injectable({
    providedIn: 'root'
})
export class AdminRouteGuardService implements CanActivate {
    env = environment;

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const allowNavigate = (this.authService.authUser &&
            this.authService.authUser.userId !== null &&
            this.authService.authUser.role === 'ADMIN');
        if (!allowNavigate) {
            return this.router.parseUrl('/home');
        }
        return allowNavigate;
    }
}
